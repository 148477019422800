import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"


const Page = ({data}) => {

  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/accessibility/',
      crumbLabel: 'ウェブアクセシビリティ'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title="JIS X 8341-3:2016附属書JBに基づく試験結果表示（2021年12月1日～2022年1月28日）" lang={lang} />
      <ContentLayout lang={lang} onlyjatext={true}>
        <h1>JIS X 8341-3:2016附属書JBに基づく試験結果表示（2021年12月1日～2022年1月28日）</h1>
        <h2>表明日</h2>
        <p>
          2022年1月28日
        </p>
        <h2>規格の規格番号及び改正年</h2>
        <p>
          JIS X 8341-3:2016
        </p>
        <h2>満たしている適合レベル</h2>
        <p>
          AA 準拠
        </p>
        <h2>対象となるウェブページに関する簡潔な説明</h2>
        <p>
          理化学研究所筑波キャンパスウェブサイト（URLがhttps://tsukuba.riken.jp/）
        </p>
        <h2>依存したウェブコンテンツ技術のリスト</h2>
        <p>
          <ul>
            <li>HTML5</li>
            <li>CSS3</li>
            <li>JavaScript</li>
          </ul>
        </p>
        <h2>試験対象のウェブページを選択した方法</h2>
        <p>
          JIS X 8341-3:2016「附属書JB（参考）試験方法　JB.1.2 b) ランダムに選択する場合」に基づき、計40ページを選択した。
        </p>
        <h2>試験を行ったウェブページのURL</h2>
        <ul>
          


          <li>https://tsukuba.riken.jp/</li>
          <li>https://tsukuba.riken.jp/en/</li>
          <li>https://tsukuba.riken.jp/access/</li>
          <li>https://tsukuba.riken.jp/en/access/</li>
          <li>https://tsukuba.riken.jp/accessibility/</li>
          <li>https://tsukuba.riken.jp/accessibility/20220131/</li>
          <li>https://tsukuba.riken.jp/campus/</li>
          <li>https://tsukuba.riken.jp/en/campus/</li>
          <li>https://tsukuba.riken.jp/campus/disclosure/</li>
          <li>https://tsukuba.riken.jp/campus/facilities/</li>
          <li>https://tsukuba.riken.jp/en/campus/facilities/</li>
          <li>https://tsukuba.riken.jp/campus/history/</li>
          <li>https://tsukuba.riken.jp/en/campus/history/</li>
          <li>https://tsukuba.riken.jp/campus/organization/</li>
          <li>https://tsukuba.riken.jp/en/campus/organization/</li>
          <li>https://tsukuba.riken.jp/centers/</li>
          <li>https://tsukuba.riken.jp/en/centers/</li>
          <li>https://tsukuba.riken.jp/contact/</li>
          <li>https://tsukuba.riken.jp/en/contact/</li>
          <li>https://tsukuba.riken.jp/experience/</li>
          <li>https://tsukuba.riken.jp/en/experience/</li>
          <li>https://tsukuba.riken.jp/experience/application/</li>
          <li>https://tsukuba.riken.jp/partnerships/</li>
          <li>https://tsukuba.riken.jp/en/partnerships/</li>
          <li>https://tsukuba.riken.jp/sitemap/</li>
          <li>https://tsukuba.riken.jp/en/sitemap/</li>
          <li>https://tsukuba.riken.jp/topics/</li>
          <li>https://tsukuba.riken.jp/en/topics/</li>
          <li>https://tsukuba.riken.jp/topics/20100419/</li>
          <li>https://tsukuba.riken.jp/topics/20110330/</li>
          <li>https://tsukuba.riken.jp/topics/20110404/</li>
          <li>https://tsukuba.riken.jp/topics/20111116/</li>
          <li>https://tsukuba.riken.jp/topics/20120425b/</li>
          <li>https://tsukuba.riken.jp/topics/20120706/</li>
          <li>https://tsukuba.riken.jp/topics/20130118a/</li>
          <li>https://tsukuba.riken.jp/topics/20130830/</li>
          <li>https://tsukuba.riken.jp/topics/20150128/</li>
          <li>https://tsukuba.riken.jp/topics/20180726/</li>
          <li>https://tsukuba.riken.jp/topics/20180727/</li>
          <li>https://tsukuba.riken.jp/topics/20200422/</li>
        </ul>
        <h2>達成基準チェックリスト</h2>
        <ul>
          <li>適用欄 「○」達成基準を適用する、「－」達成基準は適用されない</li>
          <li>結果欄 「○」達成基準を満たしている、「×」達成基準を満たしていない</li>
        </ul>
        <table class="resulttable">
          <thead>
            <tr>
              <th>細分箇条</th>
              <th>達成基準</th>
              <th >適合レベル</th>
              <th >適用</th>
              <th >結果</th>
              <th >注記</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.1.1</td>
              <td>非テキストコンテンツの達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.2.1</td>
              <td>音声だけ及び映像だけ（収録済み）の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>1.2.2</td>
              <td>キャプション（収録済み）の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>1.2.3</td>
              <td>音声解説又はメディアに対する代替コンテンツ（収録済み）の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>1.2.4</td>
              <td>キャプション（ライブ）の達成基準</td>
              <td >AA</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>1.2.5</td>
              <td>音声解説（収録済み）の達成基準</td>
              <td >AA</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>1.3.1</td>
              <td>情報及び関係性の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.3.2</td>
              <td>意味のある順序の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.3.3</td>
              <td>感覚的な特徴の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.4.1</td>
              <td>色の使用の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.4.2</td>
              <td>音声の制御の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td >1.4.3</td>
              <td >コントラスト（最低限レベル）の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.4.4</td>
              <td>テキストのサイズ変更の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>1.4.5</td>
              <td>文字画像の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.1.1</td>
              <td>キーボードの達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.1.2</td>
              <td>キーボードトラップなしの達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.2.1</td>
              <td>タイミング調整可能の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>2.2.2</td>
              <td>一時停止，停止及び非表示の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>2.3.1</td>
              <td>3 回のせん（閃）光，又はしきい（閾）値以下の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>2.4.1</td>
              <td>ブロックスキップの達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.4.2</td>
              <td>ページタイトルの達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.4.3</td>
              <td>フォーカス順序の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.4.4</td>
              <td>リンクの目的（コンテキスト内）の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.4.5</td>
              <td>複数の手段の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>2.4.6</td>
              <td>見出し及びラベルの達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td >2.4.7</td>
              <td >フォーカスの可視化の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.1.1</td>
              <td>ページの言語の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.1.2</td>
              <td>一部分の言語の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.2.1</td>
              <td>フォーカス時の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>3.2.2</td>
              <td>入力時の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.2.3</td>
              <td>一貫したナビゲーションの達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.2.4</td>
              <td>一貫した識別性の達成基準</td>
              <td >AA</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.3.1</td>
              <td>エラーの特定の達成基準</td>
              <td >A</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>3.3.2</td>
              <td>ラベル又は説明の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>3.3.3</td>
              <td>エラー修正の提案の達成基準</td>
              <td >AA</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td>3.3.4</td>
              <td>エラー回避（法的，金融及びデータ）の達成基準</td>
              <td >AA</td>
              <td >－</td>
              <td >〇</td>
              <td>対象なし</td>
            </tr>
            <tr>
              <td >4.1.1</td>
              <td >構文解析の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
            <tr>
              <td>4.1.2</td>
              <td>名前（name），役割（role）及び値（value）の達成基準</td>
              <td >A</td>
              <td >〇</td>
              <td >〇</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h2>試験実施期間</h2>
        <p>2021年12月1日～2022年1月28日</p>
      </ContentLayout>
    </Layout>
  ) 
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`;


